import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { TITLE_PREFIX, DASHBOARD_CODE, DASHBOARD_PATH, DASHBOARD_ROUTE } from './consts';
import UserModel from '../../../UserModel';

export default (app) => {
    const chartJSLib = {
        Chart,
        ArcElement,
        CategoryScale,
        LinearScale,
        LineElement,
        PointElement,
        BarElement,
        Tooltip,
        Legend,
    };
    const reactCharts = { Bar, Doughnut, Line, Pie };

    app.routes.addItem(DASHBOARD_CODE, {
        exact: true,
        path: DASHBOARD_PATH,
        title: TITLE_PREFIX,
        breadcrumbConfig: { title: 'Dashboard', clearPath: true },
        props: () => ({
            chartJSLib,
            reactCharts,
            currentUserId: UserModel.getUserId(),
        }),
        loader: () => import('@skunexus/dashboard'),
    });

    app.sideMenuItems.addItem(DASHBOARD_CODE, {
        label: TITLE_PREFIX,
        link: DASHBOARD_ROUTE,
        icon: 'overview',
        order: 10,
        breadcrumbConfig: { title: 'Dashboard', clearPath: true },
    });

    app.headerMenuItems.addItem(DASHBOARD_CODE, {
        label: TITLE_PREFIX,
        link: DASHBOARD_ROUTE,
        icon: 'Grid',
        order: 10,
    });

    app.vendorRoutes.addItem(DASHBOARD_CODE, {
        exact: true,
        path: DASHBOARD_PATH,
        breadcrumbConfig: { title: 'Dashboard', clearPath: true },
        title: TITLE_PREFIX,
        props: () => ({
            chartJSLib,
            reactCharts,
            currentUserId: UserModel.getUserId(),
        }),
        loader: () => import('@skunexus/dashboard'),
    });
};
